import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "./Profile.scss";
import { FaChevronLeft } from "react-icons/fa6";
import Header from "../../Home/Header/Header";
import Footer from "../../Home/Footer/Footer";
import kishoreImage from "../../../Assets/Aboutus Page/Dr_Kishore.png";
import { FaStar } from "react-icons/fa";

const Profile = () => {

    const navigate = useNavigate();
    const handleRedirect = () => {
        navigate("/aboutus");
    };

    const doctorDetails = {
        name: "Dr. Kishore Sunkara",
        image: kishoreImage,
        position: "CSO",
        experience: 30,
        rating: 5.0,
        reviewsLink: "https://health.usnews.com/doctors/kishore-sunkara-363983",
        reviews: ["Child Specialist", "Wonderful and caring", "Compassionate"],
        specialities: ["Child/Adolescent Psychiatry", "Adult Psychiatry"],
        about: [
            "Dr. Kishore Sunkara, MD, For over 30 years, has been helping patients throughout the Greater Fort Worth area to address their psychological needs with Psychiatric treatment that encompasses medication management and brief psychotherapy. From his private practice in Hurst Texas, Dr. Sunkara specializes in helping patients deal with issues including autism spectrum disorder, anxiety, social anxiety, post-traumatic stress disorder (PTSD), attention deficit hyperactive disorder in children & adults, depression, obsessive-compulsive disorder, trauma, and panic attacks, bipolar disorders & schizophrenia. He takes a holistic approach to helping patients make positive decisions to improve their overall health and well-being.",
            "He lives in Dallas with his wife and has a son and daughter and one grandchildren."
        ],
        experienceDetails: [
            "CEO, FocusDFW; July 1990 to Present",
            "Unit Chief Psychiatrist, Samaritan Health Center, Michigan Jan 1989 to Dec 1990",
            "Residency Training, Fair lawn Center; Michigan Jan 1985 to Apr 1990"
        ],
        memberships: [
            "ABPN, American Board of Psychiatry and Neurology",
            "CPE, American College of Physician Executives",
            "ATA, American Telemedicine Association"
        ],
        licenses: ["State of Texas, #H7837"]
    };

    return (
        <div>
            <Header />
            <div className="speaker-details-container">
                <div className="back-link pb-3">
                    <Link onClick={handleRedirect()}
                        className="back-button"
                    >
                        <FaChevronLeft /> Back
                    </Link>
                </div>

                <div className="doctor-details-section">
                    <div className="doctor-details-left">
                        <div className="doctor-name">{doctorDetails?.name}</div>
                        <div className="doctor-image">
                            <img src={doctorDetails?.image} alt={doctorDetails?.name} />
                        </div>

                        <div className="doctor-description">
                            <h5>
                                {doctorDetails?.position} <span className="d-line"></span>
                                <p>{doctorDetails?.experience} Years of Experience</p>
                            </h5>
                        </div>

                        <div className="ratings">
                            <p>
                                <FaStar />
                                &nbsp;<b>{doctorDetails?.rating}</b>&nbsp;
                                Average customer rating
                            </p>
                        </div>

                        <div className="reviews">
                            <h2>
                                Reviews &nbsp;
                                <a
                                    href={doctorDetails?.reviewsLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Read Reviews
                                </a>
                            </h2>

                            <ul>
                                {doctorDetails?.reviews.map((review, index) => (
                                    <li key={index}>{review}</li>
                                ))}
                            </ul>
                        </div>

                        <div className="speciality">
                            <h2>Specialities</h2>

                            <ul>
                                {doctorDetails?.specialities.map((speciality, index) => (
                                    <li key={index}>{speciality}</li>
                                ))}
                            </ul>
                        </div>
                    </div>

                    <div className="doctor-details-right">
                        <div className="about-content">
                            <h2>About</h2>
                            <div>
                                {doctorDetails?.about.map((paragraph, index) => (
                                    <p key={index}>{paragraph}</p>
                                ))}
                            </div>
                        </div>
                        <div className="experience">
                            <h2>Work Experience</h2>

                            <ul>
                                {doctorDetails?.experienceDetails.map((experience, index) => (
                                    <li key={index}>{experience}</li>
                                ))}
                            </ul>
                        </div>

                        <div className="memberships">
                            <h2>Memberships</h2>
                            <ul>
                                {doctorDetails?.memberships.map((membership, index) => (
                                    <li key={index}>{membership}</li>
                                ))}
                            </ul>
                        </div>

                        <div className="licence">
                            <h2>Licenses & Certifications</h2>
                            <ul>
                                {doctorDetails?.licenses.map((license, index) => (
                                    <li key={index}>{license}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Profile;