import React from 'react';
import './Commitmentsection.scss';
import commitmentImage from '../../../Assets/Aboutus Page/Our_Commiment_to_Families.png'; // Make sure the path is correct

const Commitmentsection = () => {
  return (
    <>
      <div className="about-us-family-section">
        <div className="mble-img">
          <img src={commitmentImage} alt="Happy Family" />
        </div>
        
        <div className="about-us-commitment-section">
          <div className="commitment-content">
            <h2>Our Commitment to Families</h2>
            <p>
              At Autism Complete Care, we believe families are integral to the therapeutic process. We are committed to providing therapy for children and support, education, and resources for the whole family. We strive to build strong, collaborative relationships with parents and caregivers, empowering them to advocate for their children within our clinic and in the wider community.
            </p>
          </div>

          <div className="desktop-img">
            <img src={commitmentImage} alt="Happy Family" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Commitmentsection;
